html {
  height: 100%;
}

body {
  position: relative;
  margin: 0;
  min-height: 100%;
}

.App {
  text-align: center;
  white-space: nowrap;
}

/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/

.text-align-left {
  text-align: left;
}

.selected-row {
  background-color: lightgreen;
}

.form-label {
  margin-top: 0.3em;
  font-weight: bold;
}

.table-fixed {
  overflow: auto;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 70vmin;
}
.table-fixed table {
  border-collapse: separate;
  border-spacing: 0;
}
.table-fixed th {
  background-color: white;
  position: sticky;
  top: 0;
}
.table-fixed th,
.table-fixed td {
  border: 1px solid lightgray;
}

.parent-fit-img {
  width: 100%; /* or any custom size */
  height: 100%;
  object-fit: contain;
}

.log-detail-content-label {
  text-align: center;
  border-bottom: solid 1px black;
}

.log-detail-content-label * {
  word-break: keep-all;
  font-size: 0.9em;
}

.log-detail-content {
  border: solid 1px black;
}

.log-detail-header {
  display: inline-block;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-top: 0.4em;
  margin-bottom: 0.4em;
  background-color: gainsboro;
}

.log-detail-listtable {
  font-size: 0.9em;
  text-align: center;
}

.log-detail-listtable thead {
  background-color: gainsboro;
  border: solid 1px black;
}

.disabled-image {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

/* Works for Chrome, Safari, Edge, Opera */
.input-number-hidearrow::-webkit-outer-spin-button,
.input-number-hidearrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
.input-number-hidearrow {
  appearance: textfield;
  -moz-appearance: textfield;
}

.color-on-hover:hover {
  background-color: lightgray;
}
